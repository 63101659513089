import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "list-unstyled list-inline text-center" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "list-unstyled list-inline text-center" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "list-unstyled list-inline text-center" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = { class: "list-unstyled list-inline text-center" }
const _hoisted_8 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("ul", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cardsSpade, (card, x) => {
        return (_openBlock(), _createElementBlock("li", { key: x }, [
          _createElementVNode("div", {
            class: _normalizeClass(["d-cards-m select-card", [
            _ctx.disabledcards.indexOf(card.code) > -1 && !_ctx.allowDuplicateCards
              ? 'shadow selected disabled-card'
              : '',
            `${String(card.code).toLowerCase()}`,
          ]]),
            onClick: ($event: any) => (_ctx.selectCard(card))
          }, null, 10, _hoisted_2)
        ]))
      }), 128))
    ]),
    _createElementVNode("ul", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cardsHeart, (card, x) => {
        return (_openBlock(), _createElementBlock("li", { key: x }, [
          _createElementVNode("div", {
            class: _normalizeClass(["d-cards-m select-card", [
            _ctx.disabledcards.indexOf(card.code) > -1 && !_ctx.allowDuplicateCards
              ? 'shadow selected disabled-card'
              : '',
            `${String(card.code).toLowerCase()}`,
          ]]),
            onClick: ($event: any) => (_ctx.selectCard(card))
          }, null, 10, _hoisted_4)
        ]))
      }), 128))
    ]),
    _createElementVNode("ul", _hoisted_5, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cardsClub, (card, x) => {
        return (_openBlock(), _createElementBlock("li", { key: x }, [
          _createElementVNode("div", {
            class: _normalizeClass(["d-cards-m select-card", [
            _ctx.disabledcards.indexOf(card.code) > -1 && !_ctx.allowDuplicateCards
              ? 'shadow selected disabled-card'
              : '',
            `${String(card.code).toLowerCase()}`,
          ]]),
            onClick: ($event: any) => (_ctx.selectCard(card))
          }, null, 10, _hoisted_6)
        ]))
      }), 128))
    ]),
    _createElementVNode("ul", _hoisted_7, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cardsDiamond, (card, x) => {
        return (_openBlock(), _createElementBlock("li", { key: x }, [
          _createElementVNode("div", {
            class: _normalizeClass(["d-cards-m select-card", [
            _ctx.disabledcards.indexOf(card.code) > -1 && !_ctx.allowDuplicateCards
              ? 'shadow selected disabled-card'
              : '',
            `${String(card.code).toLowerCase()}`,
          ]]),
            onClick: ($event: any) => (_ctx.selectCard(card))
          }, null, 10, _hoisted_8)
        ]))
      }), 128))
    ])
  ]))
}