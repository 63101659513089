
import { defineComponent } from "vue";
import SocketService from "@/services/SocketService";

export default defineComponent({
  name: "DealerActions",
  props: {
    room: {
      type: Object,
      required: true,
    },
    playerCards: {
      required: true,
      type: Array,
    },
    bankerCards: {
      required: true,
      type: Array,
    },
  },
  emits: ["updateState", "winnerResults"],
  setup(props) {
    /**
     * Bet Open
     *
     */
    const startBetting = () => SocketService.socket.emit("start");
    /**
     * Bet Closed
     *
     */
    const setCancel = () => SocketService.socket.emit("cancel");

    /**
     * Result
     *
     */
    const setComplete = () => {
      const pCard = props.playerCards.filter((el) => el != null);
      const bCard = props.bankerCards.filter((el) => el != null);
      // emit result
      SocketService.socket.emit("result", {
        player: pCard,
        banker: bCard,
      });
    };
    /**
     * Shuffle
     *
     */
    const shuffle = () => SocketService.socket.emit("shuffle");

    return {
      startBetting,
      setCancel,
      setComplete,
      shuffle,
    };
  },
});
