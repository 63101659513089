
import { defineComponent, onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import SocketService from "@/services/SocketService";

//Components
import GameRoomLoading from "@/views/GameRoomLoading.vue";
import RoomHeader from "@/components/gameroom/RoomHeader.vue";
/**
 * Tables
 *
 */
import BaccaratTable from "@/views/tables/BaccaratTable.vue";
import DragonTigerTable from "@/views/tables/DragonTigerTable.vue";

export default defineComponent({
  name: "DealerRoom",
  components: {
    RoomHeader,
    BaccaratTable,
    GameRoomLoading,
    DragonTigerTable,
  },
  setup() {
    // vue variables
    const route = useRoute();
    // Start Socket
    const room = ref({
      game: "",
      roomno: "",
      roomorder: "",
      shoe: "",
      state: 0,
      type: "",
      cards: [],
      result: "",
    });
    const roomLoading = ref(true);

    onMounted(() => {
      SocketService.init(
        "https://dlr.defensilx918.com",
        String(route.params.roomno)
      );
      //Init
      SocketService.socket.on("init", (data) => (room.value = data));
      // Update Current State
      SocketService.socket.on("current_state", (data) => (room.value = data));
      localStorage.setItem("roomno", String(route.params.roomno));
    });
    return {
      room,
      roomLoading,
    };
  },
});
