
import { defineComponent } from "vue";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "Invalid",
  setup() {
    const router = useRouter();
    /*
     * Back to dealer page
     */
    const backDealer = () => {
      const roomno = localStorage.getItem("roomno") || "";
      router.push({ path: `/room/${roomno}` });
    };
    return {
      backDealer,
    };
  },
});
