import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-156d0195"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "banker-card-wrapper",
  class: "col-lg-12 text-center card-box"
}
const _hoisted_2 = {
  class: "list-unstyled list-inline card-box",
  style: {"display":"block"},
  id: "banker-card"
}
const _hoisted_3 = { id: "banker-card-1" }
const _hoisted_4 = {
  key: 0,
  class: "pair-bx"
}
const _hoisted_5 = { id: "banker-card-2" }
const _hoisted_6 = { id: "banker-card-0" }
const _hoisted_7 = {
  key: 0,
  class: "banker-score"
}
const _hoisted_8 = {
  key: 1,
  class: "banker-title winner"
}
const _hoisted_9 = {
  key: 2,
  class: "player-title tie"
}
const _hoisted_10 = {
  key: 3,
  class: "banker-title cancel"
}
const _hoisted_11 = {
  key: 4,
  class: "banker-title"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("ul", _hoisted_2, [
      _createElementVNode("li", _hoisted_3, [
        (
            _ctx.state === 3 &&
            (_ctx.winner === 'c' ||
              _ctx.winner === 'd' ||
              _ctx.winner === 'g' ||
              _ctx.winner === 'h' ||
              _ctx.winner === 'k' ||
              _ctx.winner === 'l')
          )
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.$t("Pair")), 1))
          : _createCommentVNode("", true),
        _createElementVNode("div", {
          class: _normalizeClass(["d-cards", [
            _ctx.cards[0] != null ? String(_ctx.cards[0]).toLowerCase() : 'no-card',
            _ctx.cards[0] == null && _ctx.state == 2
              ? 'highlight-p'
              : String(_ctx.cards[0]).toLowerCase(),
          ]]),
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.selectCard(0)))
        }, null, 2)
      ]),
      _createElementVNode("li", _hoisted_5, [
        _createElementVNode("div", {
          class: _normalizeClass(["d-cards", [
            _ctx.cards[1] !== null ? String(_ctx.cards[1]).toLowerCase() : 'no-card',
            _ctx.cards[0] !== null && _ctx.cards[1] == null && _ctx.state == 2
              ? 'highlight-p'
              : String(_ctx.cards[1]).toLowerCase(),
          ]]),
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.selectCard(1)))
        }, null, 2)
      ]),
      _createElementVNode("li", _hoisted_6, [
        _createElementVNode("div", {
          class: _normalizeClass(["d-cards extra-card", [
            _ctx.cards[2] !== null ? String(_ctx.cards[2]).toLowerCase() : 'no-card',
            _ctx.cards[0] !== null &&
            _ctx.cards[1] !== null &&
            _ctx.cards[2] == null &&
            _ctx.state == 2
              ? 'highlight-p'
              : String(_ctx.cards[2]).toLowerCase(),
          ]]),
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.selectCard(2)))
        }, null, 2)
      ])
    ]),
    (_ctx.state === 2 || _ctx.state === 3)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_ctx.score), 1))
      : _createCommentVNode("", true),
    (
        _ctx.winner === 'a' || _ctx.winner === 'b' || _ctx.winner === 'c' || _ctx.winner === 'd'
      )
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
          _createElementVNode("span", null, _toDisplayString(_ctx.$t("BankerWin")), 1)
        ]))
      : (
        _ctx.winner === 'i' || _ctx.winner === 'j' || _ctx.winner === 'k' || _ctx.winner === 'l'
      )
        ? (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString(_ctx.$t("Tie")), 1))
        : (_ctx.winner === 'q')
          ? (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString(_ctx.$t("GameCancelled")), 1))
          : (_openBlock(), _createElementBlock("div", _hoisted_11, _toDisplayString(_ctx.$t("Banker")), 1))
  ]))
}