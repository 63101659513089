
import { defineComponent, onMounted, ref } from "vue";

export default defineComponent({
  name: "GameRoomLoading",
  emits: ["finish"],
  props: {
    game: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    let fullWidth = ref("width: 0%");
    let width = ref(0);

    const progressStart = async () => {
      width.value = width.value + 1;
      fullWidth.value = `width: ${width.value}%`;

      if (width.value == 100) emit("finish");
    };

    onMounted(() => {
      setInterval(() => {
        progressStart();
      }, 50);
    });

    return {
      progressStart,
      fullWidth,
      width,
    };
  },
});
