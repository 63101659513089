
import { defineComponent, ref, watch, computed } from "vue";
import SocketService from "@/services/SocketService";
import CardList from "@/components/gameroom/CardList.vue";
import TigerCards from "@/components/gameroom/dragontiger/TigerCards.vue";
import DragonCards from "@/components/gameroom/dragontiger/DragonCards.vue";
import DealerActions from "@/components/gameroom/dragontiger/DealerActions.vue";

interface Card {
  code: string;
  value: number;
}

export default defineComponent({
  name: "DealerRoom",
  components: {
    CardList,
    TigerCards,
    DragonCards,
    DealerActions,
  },
  props: {
    room: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const gameTimer = ref(0);
    const autoTimer = ref(0);
    const showModal = ref(false);
    const disabledcards = ref([""]);
    const currentSelector = ref({
      position: "",
      cardidx: "",
    });
    // Results
    const tigerCards = ref([null]);
    const tigerScore = computed(() =>
      props.room.card && props.room.card.length > 0
        ? props.room.card[0].score
        : 0
    );
    const dragonCards = ref([null]);
    const dragonScore = computed(() =>
      props.room.card && props.room.card.length > 0
        ? props.room.card[1].score
        : 0
    );

    // Auto Fill cards
    if (props.room.card) {
      tigerCards.value.forEach((val, index) => {
        tigerCards.value[index] = props.room.card[0].cards[index]
          ? props.room.card[0].cards[index]
          : null;
      });
      dragonCards.value.forEach((val, index) => {
        dragonCards.value[index] = props.room.card[1].cards[index]
          ? props.room.card[1].cards[index]
          : null;
      });
      disabledcards.value = props.room.card[0].cards.concat(
        props.room.card[1].cards
      );
    }

    /**
     * Toggle Card Modal
     *
     */
    const toggleCardModal = (val, index, cardIndex) => {
      showModal.value = val;
      currentSelector.value.position = index;
      currentSelector.value.cardidx = cardIndex;
    };
    /**
     * On Select Card
     *
     */
    const selectedCard = (card: Card) => {
      if (currentSelector.value.position === "banker")
        dragonCards.value[currentSelector.value.cardidx] = card.code;
      else if (currentSelector.value.position === "player")
        tigerCards.value[currentSelector.value.cardidx] = card.code;
      // Disabled the selected card.
      disabledcards.value.push(card.code);

      // sanitize cards
      const pCards = tigerCards.value.filter((e) => e !== null);
      const bCards = dragonCards.value.filter((e) => e !== null);

      // emit dealing
      SocketService.socket.emit("deal", {
        player: pCards,
        banker: bCards,
      });
      showModal.value = false;
    };
    /**
     * Timer
     *
     */
    watch(
      () => gameTimer,
      (value) => {
        if (value.value > 0) {
          setTimeout(() => {
            gameTimer.value--;
          }, 1000);
        }
        if (value.value === 0 && props.room.state === 1)
          SocketService.socket.emit("close");
      },
      { deep: true }
    );
    /**
     * Watch State
     *
     */
    watch(
      () => props.room.state,
      (value) => {
        if (value === 1 || value === 4) {
          disabledcards.value = [];
          tigerCards.value = [null, null, null];
          dragonCards.value = [null, null, null];
        } else if (
          value === 3 &&
          props.room.card &&
          props.room.card.length > 0
        ) {
          tigerCards.value.splice(
            0,
            props.room.card[0].cards.length,
            ...props.room.card[0].cards
          );
          dragonCards.value.splice(
            0,
            props.room.card[1].cards.length,
            ...props.room.card[1].cards
          );
        }

        gameTimer.value = value === 1 ? props.room.timer : 0;
      },
      { deep: true }
    );
    /**
     * Watch Card
     *
     */
    watch(
      () => props.room.card,
      (value) => {
        console.log(value);
        if (value) {
          tigerCards.value.forEach((val, index) => {
            tigerCards.value[index] = value[0].cards[index]
              ? value[0].cards[index]
              : null;
          });
          dragonCards.value.forEach((val, index) => {
            dragonCards.value[index] = value[1].cards[index]
              ? value[1].cards[index]
              : null;
          });
          disabledcards.value = value[0].cards.concat(value[1].cards);
          console.log(tigerCards.value);
          console.log(dragonCards.value);
          console.log(disabledcards.value);
        }
      },
      { deep: true }
    );
    /**
     * Status Text
     *
     */
    const getStatusText = () => {
      if (props.room.state === 0) return "ready";
      else if (props.room.state === 1 && gameTimer.value <= 5) return "nomore";
      else if (props.room.state === 1 && gameTimer.value > 0) return "bet";
      else if (props.room.state === 2) return "stop";
      else if (props.room.state === 3 && props.room.result != "q")
        return "complete";
      else if (props.room.state === 3 && props.room.result == "q")
        return "cancelled";
      else if (props.room.state === 4) return "shuffling";
      else if (props.room.state === 5) return "cancelled";
    };
    return {
      autoTimer,
      disabledcards,
      gameTimer,
      getStatusText,
      tigerCards,
      tigerScore,
      dragonCards,
      dragonScore,
      showModal,
      selectedCard,
      toggleCardModal,
    };
  },
});
