<template>
  <div class="row" style="background-color: #000">
    <div class="card header">
      <div class="card-body">
        <div class="col-md-12">
          Roomno: <span class="description">{{ room.roomno }}</span>
        </div>
        <div class="col-md-12">
          Roomorder: <span class="description">{{ room.roomorder }}</span>
        </div>
        <div class="col-md-12">
          Game:
          <span class="description">{{
            room.type === "BAC" || room.type === "SBAC"
              ? "BACCARAT"
              : "DRAGON TIGER"
          }}</span>
        </div>
        <div class="col-md-12">
          Timer: <span class="description">{{ room.timer || 0 }}</span>
        </div>
      </div>
    </div>
    <button
      type="button"
      id="btn-maximize"
      class="btn btn-warning btn-sm fullscreen"
      @click="enterFullScreen()"
    >
      전체화면
    </button>
  </div>
</template>
<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "RoomHeader",
  props: {
    room: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const enterFullScreen = () => {
      let isInFullScreen =
        (document.fullScreenElement && document.fullScreenElement !== null) ||
        document.mozFullScreen ||
        document.webkitIsFullScreen;
      if (isInFullScreen) cancelFullScreen();
      else requestFullScreen();
      return false;
    };

    const cancelFullScreen = () => {
      let el = document.body;
      let requestMethod =
        el.cancelFullScreen ||
        el.webkitCancelFullScreen ||
        el.mozCancelFullScreen ||
        el.exitFullscreen ||
        el.webkitExitFullscreen;
      if (requestMethod) {
        // cancel full screen.
        requestMethod.call(el);
      }
    };

    const requestFullScreen = () => {
      let el = document.body;
      let requestMethod =
        el.requestFullScreen ||
        el.webkitRequestFullScreen ||
        el.mozRequestFullScreen ||
        el.msRequestFullscreen;
      if (requestMethod) {
        // Native full screen.
        requestMethod.call(el);
      }
    };

    return {
      enterFullScreen,
    };
  },
});
</script>
<style>
.header {
  width: 15em;
  position: absolute;
  margin-left: 0.5em;
  margin-top: 0.5em;
  background-color: rgba(255, 255, 255, 0.15);
  color: #c0c0c0;
}
.description {
  color: gold;
  font-weight: 700;
}
.fullscreen {
  display: inline-block;
  right: 0.5em;
  margin-top: 0.5em;
  position: absolute;
  width: 7em;
}
</style>
