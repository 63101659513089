import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "col-md-12 text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      type: "button",
      class: _normalizeClass(["btn btn-primary btn-lg big-button", [_ctx.room.state >= 3 ? '' : 'disabled']]),
      id: "btn-start",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.startBetting()))
    }, _toDisplayString(_ctx.$t("Start")), 3),
    _createElementVNode("button", {
      type: "button",
      class: _normalizeClass(["btn btn-success btn-lg big-button", [
        _ctx.room.state == 2 && _ctx.playerCards[1] != null && _ctx.bankerCards[1] != null
          ? ''
          : 'disabled',
      ]]),
      id: "btn-complete",
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setComplete()))
    }, _toDisplayString(_ctx.$t("Complete")), 3),
    _createElementVNode("button", {
      type: "button",
      class: _normalizeClass(["btn btn-danger btn-lg big-button", [_ctx.room.state < 3 ? '' : 'disabled']]),
      id: "btn-cancel",
      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.setCancel()))
    }, _toDisplayString(_ctx.$t("Cancel")), 3),
    _createElementVNode("button", {
      type: "button",
      class: _normalizeClass(["btn btn-warning btn-lg big-button", [_ctx.room.state == 3 ? '' : 'disabled']]),
      id: "btn-cancel",
      onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.shuffle()))
    }, _toDisplayString(_ctx.$t("Shuffle")), 3)
  ]))
}