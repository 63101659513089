
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "CardList",
  props: {
    disabledcards: {
      type: Array,
      required: true,
    },
  },
  emits: ["selectedCard"],
  setup(props, { emit }) {
    const allowDuplicateCards = false;
    const cardsSpade = ref([
      { code: "S01", value: 1 },
      { code: "S02", value: 2 },
      { code: "S03", value: 3 },
      { code: "S04", value: 4 },
      { code: "S05", value: 5 },
      { code: "S06", value: 6 },
      { code: "S07", value: 7 },
      { code: "S08", value: 8 },
      { code: "S09", value: 9 },
      { code: "S10", value: 10 },
      { code: "S11", value: 11 },
      { code: "S12", value: 12 },
      { code: "S13", value: 13 },
    ]);
    const cardsHeart = ref([
      { code: "H01", value: 1 },
      { code: "H02", value: 2 },
      { code: "H03", value: 3 },
      { code: "H04", value: 4 },
      { code: "H05", value: 5 },
      { code: "H06", value: 6 },
      { code: "H07", value: 7 },
      { code: "H08", value: 8 },
      { code: "H09", value: 9 },
      { code: "H10", value: 10 },
      { code: "H11", value: 11 },
      { code: "H12", value: 12 },
      { code: "H13", value: 13 },
    ]);
    const cardsClub = ref([
      { code: "C01", value: 1 },
      { code: "C02", value: 2 },
      { code: "C03", value: 3 },
      { code: "C04", value: 4 },
      { code: "C05", value: 5 },
      { code: "C06", value: 6 },
      { code: "C07", value: 7 },
      { code: "C08", value: 8 },
      { code: "C09", value: 9 },
      { code: "C10", value: 10 },
      { code: "C11", value: 11 },
      { code: "C12", value: 12 },
      { code: "C13", value: 13 },
    ]);
    const cardsDiamond = ref([
      { code: "D01", value: 1 },
      { code: "D02", value: 2 },
      { code: "D03", value: 3 },
      { code: "D04", value: 4 },
      { code: "D05", value: 5 },
      { code: "D06", value: 6 },
      { code: "D07", value: 7 },
      { code: "D08", value: 8 },
      { code: "D09", value: 9 },
      { code: "D10", value: 10 },
      { code: "D11", value: 11 },
      { code: "D12", value: 12 },
      { code: "D13", value: 13 },
    ]);

    const selectCard = (card) => {
      emit("selectedCard", card, props.disabledcards);
    };

    return {
      cardsSpade,
      cardsHeart,
      cardsClub,
      cardsDiamond,
      selectCard,
      allowDuplicateCards,
    };
  },
});
