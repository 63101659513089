import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3b396a5e"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "banker-card-wrapper",
  class: "col-lg-12 text-center card-box"
}
const _hoisted_2 = {
  class: "list-unstyled list-inline card-box",
  style: {"display":"block"},
  id: "banker-card"
}
const _hoisted_3 = { id: "banker-card-1" }
const _hoisted_4 = {
  key: 0,
  class: "banker-score"
}
const _hoisted_5 = {
  key: 1,
  class: "banker-title winner"
}
const _hoisted_6 = {
  key: 2,
  class: "banker-title tie"
}
const _hoisted_7 = {
  key: 3,
  class: "banker-title cancel"
}
const _hoisted_8 = {
  key: 4,
  class: "banker-title"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("ul", _hoisted_2, [
      _createElementVNode("li", _hoisted_3, [
        _createElementVNode("div", {
          class: _normalizeClass(["d-cards", [
            _ctx.cards[0] != null ? String(_ctx.cards[0]).toLowerCase() : 'no-card',
            _ctx.cards[0] == null && _ctx.state == 2
              ? 'highlight-p'
              : String(_ctx.cards[0]).toLowerCase(),
          ]]),
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.selectCard(0)))
        }, null, 2)
      ])
    ]),
    (_ctx.state === 2 || _ctx.state === 3)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.score), 1))
      : _createCommentVNode("", true),
    (_ctx.winner === 'a')
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createElementVNode("span", null, _toDisplayString(_ctx.$t("DragonWin")), 1)
        ]))
      : (_ctx.winner === 'i')
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.$t("Tie")), 1))
        : (_ctx.winner === 'q')
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_ctx.$t("GameCancelled")), 1))
          : (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(_ctx.$t("Dragon")), 1))
  ]))
}