import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 1,
  id: "content-wrapper",
  class: "container-fluid"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GameRoomLoading = _resolveComponent("GameRoomLoading")!
  const _component_RoomHeader = _resolveComponent("RoomHeader")!
  const _component_BaccaratTable = _resolveComponent("BaccaratTable")!
  const _component_DragonTigerTable = _resolveComponent("DragonTigerTable")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.roomLoading)
      ? (_openBlock(), _createBlock(_component_GameRoomLoading, {
          key: 0,
          game: _ctx.$route.params.code,
          onFinish: _cache[0] || (_cache[0] = ($event: any) => (_ctx.roomLoading = false))
        }, null, 8, ["game"]))
      : _createCommentVNode("", true),
    (!_ctx.roomLoading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_ctx.room.type != '')
            ? (_openBlock(), _createBlock(_component_RoomHeader, {
                key: 0,
                room: _ctx.room
              }, null, 8, ["room"]))
            : _createCommentVNode("", true),
          (_ctx.room.type === 'BAC' || _ctx.room.type === 'SBAC')
            ? (_openBlock(), _createBlock(_component_BaccaratTable, {
                key: 1,
                room: _ctx.room
              }, null, 8, ["room"]))
            : (_ctx.room.type === 'DT')
              ? (_openBlock(), _createBlock(_component_DragonTigerTable, {
                  key: 2,
                  room: _ctx.room
                }, null, 8, ["room"]))
              : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ], 64))
}