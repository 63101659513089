
import { defineComponent } from "vue";

export default defineComponent({
  name: "PlayerCards",
  emits: ["cardModal"],
  props: {
    cards: {
      required: true,
      type: Array,
    },
    score: {
      type: Number,
    },
    winner: {
      type: String,
    },
    state: {
      required: true,
      type: Number,
    },
  },
  setup(props, { emit }) {
    const selectCard = (cardIndex: number) => {
      if (props.state !== 2) return false;
      emit("cardModal", true, "player", cardIndex);
    };

    return {
      selectCard,
    };
  },
});
