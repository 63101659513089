import { Socket, io } from "socket.io-client";

class SocketService {
  public static socket: Socket;
  /**
   * Initialize
   *
   */
  public static init(socketURL: string, room: string) {
    const socket = io(socketURL, {
      query: { room },
    });

    socket.on("disconnect", () => (location.href = "/invalid"));
    socket.on("error", (err) => alert(err));

    SocketService.socket = socket;
  }
}

export default SocketService;
